<template>
  <div class="footer">
    <div class="banquan">2022 NSFOCUS 绿盟科技 www.nsfocus.com All Rights Reserved .</div>
    <div class="biean">
      <img class="footer-icon" src="../assets/images/footer-icon.png" alt="" />
      公安备案号 11010502033739丨京ICP备11038982号-10
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterComponent',
};
</script>

<style scoped></style>
