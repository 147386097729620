import axios from 'axios';
import Config from '../config/config';

// create an axios instance
const service = axios.create({
  baseURL: Config.api.gateway.general, // api 的 base_url
  timeout: 10000, // request timeout
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
});

service.postJson = (url, params, config) => new Promise((resolve, reject) => {
  service
    .post(url, params, config)
    .then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      },
    )
    .catch((error) => {
      reject(error);
    });
});

service.getJson = (url, params) => new Promise((resolve, reject) => {
  service
    .get(url, params)
    .then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      },
    )
    .catch((error) => {
      reject(error);
    });
});
// response interceptor
service.interceptors.request.use(
  (config) => {
    if (config.method === 'post') {
      config.data = config.data || {};
      config.data.tenantId = Config.tenantId;
      // config.data = qs.stringify({ ...config.data });
    } else {
      config.params = config.params || {};
      config.params.tenantId = Config.tenantId;
    }
    return config;
  },
  (error) => {
    console.error(error);
    Promise.reject(error);
  },
);

service.interceptors.response.use(
  (response) => {
    const res = response.data;

    if (response.config.baseURL.indexOf('project') > -1) {
      if (res.result !== 0) {
        // alert(res.body.desc);
        return Promise.reject(res.body);
      }
      return Promise.resolve(res.body);
    }

    if (res.body.result !== 0) {
      // alert(res.body.desc);
      return Promise.reject(res.body);
    }
    return Promise.resolve(res.body);
  },
  (error) => {
    console.log(`err${error}`); // for debug
    return Promise.reject(error);
  },
);

export default service;
