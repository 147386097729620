<template>
  <div class="c-banner">
    <div class="banner-item">
      <img src="@/assets/images/banner01.png" class="pc-mark" />
      <img src="@/assets/images/banner02.png" class="wap-mark" />
    </div>
  </div>
</template>

<script >

export default {
  name: 'BannerComponent',
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
