<template>
  <div class="videolist">
    <div class="videolist-title">
      <c-title :text="'精彩视频'" :tips="'WONDERFUL VIDEO'"></c-title>
      <div class="c-swiper-btns pc-mark">
        <div
          class="btn-item left videolist-next-left"
          :class="{ disabled: activeIndex == 0 }"
        ></div>
        <div
          class="btn-item right videolist-next-right"
          :class="{ disabled: activeIndex == videoLists.length - 1 }"
        ></div>
      </div>
    </div>
    <div class="pc-videolist">
      <div class="left-box">
        <div
          class="item"
          v-for="(e, i) in menus"
          :class="{ active: checked == i }"
          :key="i"
          @click="checkFn(i)"
        >
          {{ e }}
        </div>
      </div>
      <div class="right-box">
        <div
          class="swiper pc-video-swiper pc-mark videolistSwiper-pc-container"
        >
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(m, j) in videoLists" :key="j">
              <div class="main-right-item">
                <div
                        class="one-time player-video-min"
                        v-if="m[0]"
                        @click="openVideo(m[0])"
                >
                  <img
                          class="news-img"
                          :src="require('@/assets/images/video/' + m[0].img)"
                          alt=""
                  />
                  <p>{{ m[0].name }}</p>
                  <img class="player" src="../assets/images/video-player.png" />
                </div>
                <div
                        class="one-time player-video-min"
                        v-if="m[1]"
                        @click="openVideo(m[1])"
                >
                  <img
                          class="news-img"
                          :src="require('@/assets/images/video/' + m[1].img)"
                          alt=""
                  />
                  <p>{{ m[1].name }}</p>
                  <img class="player" src="../assets/images/video-player.png" />
                </div>
              </div>
              <div class="main-right-item">
                <div
                  class="one-time player-video-min"
                  v-if="m[2]"
                  @click="openVideo(m[2])"
                >
                  <img
                    class="news-img"
                    :src="require('@/assets/images/video/' + m[2].img)"
                    alt=""
                  />
                  <p>{{ m[2].name }}</p>
                  <img class="player" src="../assets/images/video-player.png" />
                </div>
                <div
                  class="one-time player-video-min"
                  v-if="m[3]"
                  @click="openVideo(m[3])"
                >
                  <img
                    class="news-img"
                    :src="require('@/assets/images/video/' + m[3].img)"
                    alt=""
                  />
                  <p>{{ m[3].name }}</p>
                  <img class="player" src="../assets/images/video-player.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="swiper pc-video-swiper wap-mark videolistSwiper-wap-container"
        >
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(m, j) in videoLists" :key="j">
              <div
                class="main-left-item-min"
                @click="openVideo(s)"
                v-for="(s, n) in m"
                :key="n"
              >
                <div class="player-video-min">
                  <img
                    class="news-img"
                    :src="require('@/assets/images/video/' + s.img)"
                    alt=""
                  />
                  <img class="player" src="../assets/images/video-player.png" />
                </div>
                <p>{{ s.name }}</p>
              </div>
            </div>
          </div>
          <div
            class="swiper-pagination wap-videolistSwiper-swiper-pagination"
          ></div>
        </div>
      </div>
    </div>
    <div class="video-box" ref="article" v-if="videoVisible">
      <div class="video-box__body">
        <div class="close" @click="closeDialog">
          <img src="../assets/images/close.png" alt="" />
        </div>
        <div class="video-box__content">
          <iframe
            :src="url"
            class="video-box-iframe"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper, { Pagination, Navigation } from "swiper";
Swiper.use([Navigation, Pagination]);
import CTitle from "@/components/Title.vue";
import {
  mainForums,
  dataForums,
  financeForums,
  educationForums,
} from "@/common/data";
export default {
  name: "VideoList",
  components: { CTitle },
  data() {
    return {
      videoList: [],
      activeIndex: 0,
      checked: 0,
      menus: ["主论坛", "“创新派”数据安全治理与防护论坛", "“实战派”金融数字化转型论坛", "“学院派”产学研创新融合论坛"],
      videoVisible: false,
      isPc: true,
      videoLists: [],
      swip: null,
      url: "",
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkFn(i) {
      this.swip?.destroy();
      this.checked = i;
      this.initSwiper();
    },
    async init() {
      this.initSwiper();
    },
    initSwiper() {
      this.activeIndex = 0;
      if (this.checked == 0) {
        this.videoList = mainForums;
      } else if (this.checked == 1) {
        this.videoList = dataForums;
      } else if (this.checked == 2) {
        this.videoList = financeForums;
      } else if (this.checked == 3) {
        this.videoList = educationForums;
      } else {
        this.videoList = mainForums;
      }
      this.$nextTick(() => {
        if (document.body.clientWidth > 750) {
          let arr = [];
          for (let i = 0; i < this.videoList.length; i += 4) {
            arr.push(this.videoList.slice(i, i + 4));
          }
          this.videoLists = arr;
          this.swip = new Swiper(".videolistSwiper-pc-container", {
            slidesPerView: 1,
            observer: true,
            observerParents: true,
            updateOnWindowResize: false, //分辨率改变时防止样式错乱！
            navigation: {
              prevEl: ".videolist-next-left",
              nextEl: ".videolist-next-right",
            },
            on: {
              slideChangeTransitionEnd: (swiper) => {
                // console.log(swiper.activeIndex)
                this.activeIndex = swiper.activeIndex;
              },
            },
          });
        } else {
          let arr1 = [];
          for (let i = 0; i < this.videoList.length; i += 4) {
            arr1.push(this.videoList.slice(i, i + 4));
          }
          this.videoLists = arr1;
          this.swip = this.swiper1 = new Swiper(
            ".videolistSwiper-wap-container",
            {
              slidesPerView: 1,
              observer: true,
              observerParents: true,
              updateOnWindowResize: false, //分辨率改变时防止样式错乱！
              pagination: {
                el: ".wap-videolistSwiper-swiper-pagination",
              },
            }
          );
        }
        this.swip.slideTo(0);
      });
    },
    openVideo(e) {
      this.url = e.videoUrl;
      this.videoVisible = true;
    },
    closeDialog() {
      this.videoVisible = false;
      this.url=''
    },
  },
};
</script>

<style scoped></style>
