<template>
  <div class="metting-siginUp-dialog" v-if="signUpShow">
    <div class="metting-siginUp-dialog-body">
      <div class="close" @click="closeDialog">
        <img src="../assets/images/img04.png" alt=""/>
      </div>
      <h3>绿盟科技TechWorld2022观看直播</h3>
      <input type="number" v-model="phone" placeholder="请输入你的报名手机号"/>
      <p>{{ txt }}</p>
      <div class="metting-siginUp-submit-btn" @click="submit">提交</div>
      <div class="metting-siginUp-btn" @click="signTo">去报名</div>
    </div>
  </div>
</template>

<script>
import {signupUrl,tsignupUrl,shangWuZhuHui,tshangWuZhuHui, xiaWuZhuHui,txiaWuZhuHui,lives,tlives} from "@/common/data";
import require from "../services";
import $config from "../config/config";

export default {
  name: "SiginUpDialog",
  data() {
    return {
      phone: "",
      txt: "",
      signupUrl:'',
      shangWuZhuHui:'',
      xiaWuZhuHui:'',
      lives:'',
      webinarId:'',
      curWebinarId: '',
      curCustomFormId: '',
      isMobile:  !!navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)
    }
  },
  created(){
    if(window.location.href.indexOf('local')>-1){
      this.shangWuZhuHui=tshangWuZhuHui;
      this.xiaWuZhuHui=txiaWuZhuHui;
      this.signupUrl=tsignupUrl;
      this.lives=tlives;
      this.webinarId=$config.twebinarId;
    }else {
      this.shangWuZhuHui=shangWuZhuHui;
      this.xiaWuZhuHui=xiaWuZhuHui;
      this.signupUrl=signupUrl;
      this.lives=lives;
      this.webinarId=$config.webinarId;
    }
  },
  props: {
    signUpShow: {
      type: Boolean,
      default: false,
    },
    currentWebinarId: {
      type: String,
      default: '',
    },
    currentCustomFormId: {
      type: String,
      default: '',
    },
    currentUrl: {
      type: String,
      default: '',
    },
  },
  watch: {
    currentWebinarId() {
      this.curWebinarId = this.currentWebinarId;
    },
    currentCustomFormId() {
      this.curCustomFormId = this.currentCustomFormId;
    } ,
    currentUrl() {
      this.curUrl = this.currentUrl;
    }
  },
  methods: {
    closeDialog() {
      this.$emit('close', false)
    },
    submit() {
      console.log($config.mettingStatus);
      if (!this.phone) {
        this.txt = "请输入手机号";
        return;
      }else {
        this.txt = "";
      }
      if(!new RegExp(/^(1(3|4|5|6|7|8|9)\d{9})$/).test(this.phone)){
        this.txt = "请输入正确的手机号";
        return;
      }else {
        this.txt = "";
      }
      var self = this;
      require.contactsLogin({
        value: this.phone,
        webinarId: this.webinarId
      }).then((data)=>{
        console.log(data)
        if(data.result=='0'){
          if(data.content && parseInt(
              data.content.signUpId
          )!=0){
           const  formData=[];
           Object.keys(data.content.contact).map((key)=>{
             formData.push({
               fieldName: key,
               value: data.content.contact[key],
             })
           });
           $config.mainIsSignUp=true;

           let i=0;
            this.lives.forEach((item)=>{
              require.contactsLogin({
                value: this.phone,
                webinarId: item.webinarId
              }).then((res)=>{
                if (res.content && parseInt(res.content.signUpId) > 0) {
                  i=i+1;
                  if(i==this.lives.length){
                    self.$emit('close', false);
                    // 报过主会 需要报名当前的分会
                    if($config.mettingStatus == '1'){
                      window.location.href = this.signupUrl;
                    }else if ($config.mettingStatus == '2') {
                      //上午场进主会
                      window.location.href = this.shangWuZhuHui.url;
                    } else {
                      // 有currentUrl  说明是分会 点击那个分会 进那个
                      if(this.currentUrl){
                        window.location.href = this.currentUrl;
                      }else {
                        window.location.href = this.xiaWuZhuHui.url;
                      }
                    }
                  }
                }else {
                  require.silentSignUp({
                    formData:formData,
                    webinarId:item.webinarId,
                    moduleId: 2,
                    tenantId: $config.tenantId,
                    formId:  item.customFormId,
                    channel: this.$route.query.markCode || '',
                    weChatId: '',
                    openId:'',
                    sess: '',
                    isPreview: '0',
                    signUpSource: this.isMobile ? 'WAP' : 'PC',
                    isUnLimit: 0,  //无限制观看静默报名 1 是 0 否
                    // markCode 与 marketingSess 为用于统计的埋点相关参数
                    'markCode': this.$route.query.markCode || '',
                  }).then(()=>{
                    i=i+1;
                    if(i==this.lives.length){
                      self.$emit('close', false);
                      // 报过主会 需要报名当前的分会
                      if($config.mettingStatus == '1'){
                        window.location.href = this.signupUrl;
                      }else if ($config.mettingStatus == '2') {
                        //上午场进主会
                        window.location.href = this.shangWuZhuHui.url;
                      } else {
                        // 有currentUrl  说明是分会 点击那个分会 进那个
                        if(this.currentUrl){
                          window.location.href = this.currentUrl;
                        }else {
                          window.location.href = this.xiaWuZhuHui.url;
                        }
                      }
                    }
                  })
                }
              })
            });
          }else {
            this.txt = "此手机号未报名";
          }
        }else {
         this.txt = "此手机号未报名";
        }
      });


    },
    signTo() {
      this.$emit('close', false);
      window.open(this.signupUrl);
    },
  },

}
</script>

<style scoped>

</style>
