<template>
  <div class="c-title" :class="{ white: $props.type == 1 }">
    <div class="title-tit">{{ $props.text }}</div>
    <div class="title-txt">{{ $props.tips }}</div>
  </div>
</template>

<script >

export default {
  name: "c-title",
  props: {
    text: String,
    tips: String,
    type: Number,
  },
  data() {
    return {

    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
