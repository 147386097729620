export default {
  NODE_ENV: "production",
  tenantId: 100167, // 租户ID
  schemaId: 3686,
  webinarId:122097, //主会id
  twebinarId:123426, //主会id
  mainIsSignUp:false,
  mettingStatus:"1",  //1 会前 2 会中上午 3 会中下午 4 会议结束 5 会后
  api: {
    gateway: {
      general: "https://api.smarket.net.cn",
      project: "https://projectapi.smarket.net.cn",
      JsonUrl: "https://static.smarket.net.cn",
    },
    ungateway: {
      // uat图片上传地址
      contentUrl: "https://s2-content.smarket.net.cn/index.php",
    },
    cdn: "https://s2-cdn.smarket.net.cn",
    wxauth: "https://f.smarket.net.cn/memberb/public/wx_interface/auth",
  },
  shareInfo: {
    tenantId: 100167,
    weChatId: 355576, // 微信ID
    shareApiUrl: "https://s2-api.smarket.net.cn",
    title: "智慧创造新价值 联想智慧中国行2020 - 大会官网",
    desc: "随着5G技术、人工智能和智能物联网的快速发展，智能变革时代已经到来，中国将全面领航产业智能化升级。",
    imgUrl:
        "https://uatf.smarket.net.cn/uat/sFile/tenantId2740/2020-06-22/image/1592813521146087.jpg",
  },
  articleCategoryId: "2208012440683503",
  fileArticleCategoryId: "2208031808320349",
  guideId: "2208030742605554",
  fileDetailUrl:
      "https://file.smarket.net.cn/s/template/hRZx3c/?accKey=6b843f7d1fbadb1c6c30d0672265398a#/pc/fileDetail?fileId=",
  isShowBIgView:true,
  isShowFileDownload:true
};
