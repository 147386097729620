<template>
  <div class="c-guest">
    <div class="guest-title">
      <c-title :text="'重磅嘉宾'" :tips="'SPEAKERS'"></c-title>
      <div class="guest-title-tips-box">
        <div class="c-swiper-btns pc-mark">
          <div class="btn-item left guest-swiper-left" :class="{ disabled: activeIndex == 0 }"></div>
          <div
              class="btn-item right guest-swiper-right"
              :class="{ disabled: activeIndex == 1 }"
          ></div>
        </div>
        <div class="guest-tips ">排序不分先后</div>
      </div>
    </div>
    <div class="guest-main">
      <div class="swiper pc-mark" ref="guestSwiper">
        <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(o, i) in swiperList" :key="i">
                <div class="guest-items" v-for="(m, j) in o" :key="j">
                  <div class="item-top">
                    <div class="guest-img">
                      <img :src="m.img" alt="" />
                    </div>
                    <div class="guest-name">{{ m.name }}</div>
                  </div>
                  <div class="item-bottom">
                    <div class="item-text">{{ m.company }}</div>
                    <div class="item-text">{{ m.job }}</div>
                  </div>
                </div>
            </div>
        </div>
      </div>
      <div class="guest-list wap-mark">
        <div v-for="(m, j) in list" :key="j">
          <div class="guest-items" v-if="j < maxLen">
            <div class="item-top">
              <div class="guest-img">
                <img :src="m.img" alt="" />
              </div>
              <div class="guest-name">{{ m.name }}</div>
            </div>
            <div class="item-bottom">
              <div class="item-text">{{ m.company }}</div>
              <div class="item-text">{{ m.job }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="guest-more wap-mark" @click="setMaxLen()" v-if="maxLen < list.length">
        <div class="more-text">查看更多</div>
        <div class="more-img">
          <img src="@/assets/images/more.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import CTitle from "@/components/Title.vue";
import Swiper, { Pagination, Navigation } from "swiper";
import {guestsList} from "../common/data";

Swiper.use([Navigation, Pagination]);
export default {
  name: "MeetingGuest",
  components: { CTitle },
  props: {
    msg: String,
  },
  data() {
    return {
      swiper: null ,
      maxLen: 4,
      activeIndex: 0,
      list:guestsList,
      swiperList: [
        [
          {
            img: "./img/guest01.png",
            name: "王军民",
            company: "绿盟科技集团",
            job: "首席技术官",
            index: 0,
          },
        ],
      ],
    };
  },
  created() {
    // this.list = [];
    this.swiperList = [];
    for (let i = 0; i <= Math.floor(this.list.length / 10); i++) {
      const arr = [];
      for (let j = 0; j < 10; j++) {
        if(this.list[i * 10 + j]){
          arr.push(this.list[i * 10 + j]);
        }
      }
      if(arr.length>0){
        this.swiperList.push(arr);
      }
    }
  },
  mounted() {
    this.initSwiper();
  },
  methods: {
    setMaxLen() {
      this.maxLen = this.list.length;
    },
    initSwiper() {
      this.$nextTick(() => {
        const dom = this.$refs.guestSwiper;
        if (document.body.clientWidth > 900) {
          this.swiper = new Swiper(dom, {
            slidesPerView: 1,
            navigation: {
              prevEl: ".guest-swiper-left",
              nextEl: ".guest-swiper-right",
            },
            on: {
              slideChangeTransitionEnd: () => {
                this.activeIndex = this.swiper.activeIndex;
              },
            },
          });
        } else {
          this.swiper?.destroy();
        }
      })
    },
  },
};
</script>
<style scoped lang="scss"></style>
