<template>
  <div class="c-agenda">
    <header class="agenda-header">
      <div class="header-tit">会议日程</div>
      <div class="header-txt">AGENDA</div>
    </header>
    <section class="agenda-main">
      <div class="agenda-title">
        <div class="title-item" :class="{ active: index == 0 }" @click="selectIndex(0)">主论坛</div>
        <div class="title-item" :class="{ active: index == 1 }" @click="selectIndex(1)">
          “创新派”数据安全治理与防护论坛
        </div>
        <div class="title-item" :class="{ active: index == 2 }" @click="selectIndex(2)">
          “实战派”金融数字化转型论坛
        </div>
        <div class="title-item" :class="{ active: index == 3 }" @click="selectIndex(3)">
          “学院派”产学研创新融合论坛
        </div>
      </div>
      <div class="agenda-date">
        <div class="date-img">
          <img src="@/assets/images/img02.png" alt="" />
        </div>
        <div class="date-txt">{{ times[index] }}</div>
      </div>
      <div class="agenda-list">
        <div class="list-wrap">
          <div class="list-items items-header pc-mark">
            <div class="item-time">演讲时间</div>
            <div class="item-right">
              <div class="item-topic">演讲议题</div>
              <div class="item-user">
                <div class="user-name">演讲人</div>
              </div>
            </div>
          </div>
          <div v-for="(o, i) in list[index]" :key="i">
            <div class="list-items">
              <div class="item-icon pc-mark" v-if="o.time"></div>
              <div class="item-time">
                <span>{{ o.times[0] }}</span>
                <span v-if="o.times[1]">-</span>
                <span>{{ o.times[1] }}</span>
              </div>
              <div class="item-right">
                <div class="item-topic" v-html="o.title "> </div>
                <div class="item-user">
                  <div class="user-name" :class="{ 'wap-mr': o.user }">
                    {{ o.user }}
                  </div>
                  <div class="user-job" v-for="(m, n) in o.job" :key="n">
                    <span>{{ m }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="agenda-tips" v-if="mettingStatus==1">会议议程以当天实际发生为准</div>
    </section>
  </div>
</template>

<script >
import { agendaList, agendaTimes } from '@/common/data';
import $config from "../config/config";

export default {
  name: 'MettingAgenda',
  props: {
    msg: String,
  },
  data() {
    return {
      index: 0,
      times: agendaTimes,
      list: agendaList,
      mettingStatus:''
    };
  },
  created() {
   this.mettingStatus =$config.mettingStatus;
    for (let i = 0; i < this.list.length; i++) {
      for (let j = 0; j < this.list[i].length; j++) {
        this.list[i][j].times = this.list[i][j].time.split('-');
      }
    }
  },
  methods: {
    selectIndex(i) {
      this.index = i;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
