<template>
  <div class="hotNews">

    <div class="pcHotNews">
      <Title :text="'热门资讯'" :tips="'HOT NEWS'"></Title>
      <div class="hot-news-content">
        <div class="hot-nav">
          <div
            class="nav-item"
            :class="currentType == 'video' ? 'active' : ''"
            @click="changeNav('video')"
          >
            <img
              class="video-icon"
              src="../assets/images/video-icon.png"
              v-if="currentType == 'video'"
            />
            <img
              class="video-icon"
              src="../assets/images/video-icon-green.png"
              v-if="currentType !== 'video'"
            />视频
          </div>
          <div
            class="nav-item"
            :class="currentType == 'article' ? 'active' : ''"
            @click="changeNav('article')"
          >
            <img
              class="article-icon"
              v-if="currentType !== 'article'"
              src="../assets/images/article-icon-green.png"
            />
            <img
              class="article-icon"
              v-if="currentType == 'article'"
              src="../assets/images/article-icon.png"
            />文章
          </div>
          <div
              v-if="isShowFileDownload"
            class="nav-item"
            :class="currentType == 'file' ? 'active' : ''"
            @click="changeNav('file')"
          >
            <img
              class="article-icon"
              v-if="currentType !== 'file'"
              src="../assets/images/download-icon-green.png"
            />
            <img
              class="article-icon"
              v-if="currentType == 'file'"
              src="../assets/images/download-icon-with.png"
            />资料下载
          </div>
        </div>
        <div class="video-content-box" v-if="currentType == 'video'">
          <div class="swiper pc-video-content">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="item in videoLists" :key="item.name">
                <div class="news-img-box">
                  <img
                    class="news-img"
                    :src="require('@/assets/images/video/' + item.img)"
                    alt=""
                  />
                  <img
                    @click="openVideoModal(item)"
                    class="video-player"
                    src="../assets/images/video-player.png"
                    alt=""
                  />
                </div>
                <div class="news-info-box">
                  <div class="news-info">{{ item.name }}</div>

                  <div class="new-more" @click="openVideoModal(item)">
                    <img class="more-icon" src="../assets/images/video-player-with.png" alt="" />
                    <span class="more-text">立即播放 ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="video-content-box" v-if="currentType == 'article'">
          <div class="swiper  pc-article-content">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="item in articleLists" :key="item.id">
                <div class="news-img-box">
                  <img
                    class="news-img"
                    alt=""
                    @click="openArticleModal(item)"
                    :src="item.coverImageRealPath"
                  />
                </div>
                <div class="news-info-box">
                  <div class="news-info">{{ item.title }}</div>
                  <div class="new-more" @click="openArticleModal(item)">
                    <img class="more-icon" src="../assets/images/article-icon-with.png" alt="" />
                    <span class="more-text">查看更多 ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="video-content-box" v-if="currentType == 'file'">
          <div class="swiper  pc-file-content">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="item in fileLists" :key="item.id">
                <div class="news-img-box">
                  <img
                    class="news-img"
                    alt=""
                    @click="openFile(item)"
                    :src="item.coverImageRealPath"
                  />
                </div>
                <div class="news-info-box">
                  <div class="news-info">{{ item.title }}</div>
                  <div class="new-more" @click="openFile(item)">
                    <img class="more-icon" src="../assets/images/download-icon-with.png" alt="" />
                    <span class="more-text">资料下载 ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pager-nex" v-if="currentType == 'video' || 'file'">
        <div class="next next-left">
          <img class="hot-left" src="../assets/images/hot-left-active.png" alt="" />
        </div>
        <div class="next next-right">
          <img class="hot-right" src="../assets/images/hot-right-active.png" alt="" />
        </div>
      </div>
    </div>
    <div class="wapHotNews">
      <Title :text="'热门资讯'" :tips="'HOT NEWS'"></Title>
      <div class="hot-news-content">
        <div class="hot-nav">
          <div
            class="nav-item"
            :class="currentType == 'video' ? 'active' : ''"
            @click="changeNav('video')"
          >
            视频
          </div>
          <div
            class="nav-item"
            :class="currentType == 'article' ? 'active' : ''"
            @click="changeNav('article')"
          >
            文章
          </div>
          <div
              v-if="isShowFileDownload"
            class="nav-item"
            :class="currentType == 'file' ? 'active' : ''"
            @click="changeNav('file')"
          >
            资料下载
          </div>
        </div>
        <div class="video-content-box" v-if="currentType == 'video'">
          <div class="swiper wap-video-content">
            <div class="swiper-wrapper">
              <div class="swiper-slide new-item" v-for="item in videoLists" :key="item.name">
                <div class="news-img-box">
                  <img
                    class="news-img"
                    :src="require('@/assets/images/video/' + item.img)"
                    alt=""
                  />
                  <img
                    @click="openVideoModal(item)"
                    class="video-player"
                    src="../assets/images/video-player.png"
                    alt=""
                  />
                </div>
                <div class="news-info-box">
                  <div class="news-info">{{ item.name }}</div>
                </div>
                <div class="new-more" @click="openVideoModal(item)">
                  <img class="more-icon" src="../assets/images/video-player-green.png" alt="" />
                  <span class="more-text">立即播放</span>
                </div>
              </div>
            </div>
            <div class="swiper-pagination wap-video-content-swiper-pagination"></div>
          </div>
        </div>
        <div class="video-content-box" v-if="currentType == 'article'">
          <div class="swiper-container wap-article-content">
            <div class="swiper-wrapper">
              <div class="swiper-slide new-item" v-for="item in articleLists" :key="item.id">
                <div class="news-img-box">
                  <img
                    class="news-img"
                    :src="item.coverImageRealPath"
                    alt=""
                    @click="openArticleModal(item)"
                  />
                </div>
                <div class="news-info-box">
                  <div class="news-info">{{ item.title }}</div>
                </div>
                <div class="new-more" @click="openArticleModal(item)">
                  <img class="more-icon" src="../assets/images/article-icon-green.png" alt="" />
                  <span class="more-text">查看更多</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="video-content-box" v-if="currentType == 'file'">
          <div class="swiper wap-file-content">
            <div class="swiper-wrapper">
              <div class="swiper-slide new-item" v-for="item in fileLists" :key="item.id">
                <div class="news-img-box">
                  <img
                    class="news-img"
                    :src="item.coverImageRealPath"
                    alt=""
                    @click="openFile(item)"
                  />
                </div>
                <div class="news-info-box">
                  <div class="news-info">{{ item.title }}</div>
                  <div class="new-more" @click="openFile(item)">
                    <img class="more-icon" src="../assets/images/article-icon-green.png" alt="" />
                    <span class="more-text">立即下载</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-pagination wap-file-content-swiper-pagination"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="article-box" ref="article" v-if="articleVisible">
      <div class="article-box__body">
        <div class="close" @click="closeDialog">
          <img src="../assets/images/articleClose.png" alt="" />
        </div>
        <div class="article-box__content">
          <div class="article-title">{{ currentDetail.title }}</div>
          <div class="article-content" v-html="currentDetail.content"></div>
        </div>
      </div>
    </div>
    <div class="video-box" ref="article" v-if="videoVisible">
      <div class="video-box__body">
        <div class="close" @click="closeDialog">
          <img src="../assets/images/close.png" alt="" />
        </div>
        <div class="video-box__content">
          <iframe
            v-if="isPc"
            :src="currentVideo.videoUrl"
            class="video-box-iframe"
            frameborder="0"
            allowfullscreen
          ></iframe>
          <iframe
            v-if="!isPc"
            :src="currentVideo.videoUrl"
            class="video-box-iframe"
            width="320"
            height="380"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import require from "../services";
import $Config from "../config/config";
import Title from "./Title";
import Swiper, { Pagination, Navigation } from "swiper";

Swiper.use([Navigation, Pagination]);

const videoList = [
  {
    videoUrl: "https://mudu.tv/show/videolink/lj8vay95/origin",
    img: "relang.png",
    name: "TechWorld2022 热浪来袭",
  },
  {
    videoUrl: "https://mudu.tv/show/videolink/m3n087e0/origin",
    img: "zhitong.png",
    name: "TECH直通车|本期嘉宾：叶晓虎", },
  {
    videoUrl: "https://mudu.tv/show/videolink/m3n087j0/origin",
    img: "zhitong.png",
    name: "TECH直通车|本期嘉宾：李少鹏", },
  {
    videoUrl: "https://mudu.tv/show/videolink/mzxay901/origin",
    img: "zhitong.png",
    name: "TECH直通车|本期嘉宾：刘文懋", },
  {
    videoUrl: "https://mudu.tv/show/videolink/mv2y8v36/origin",
    img: "zhitong.png",
    name: "TECH直通车|本期嘉宾：王军民", },
  {
    videoUrl: "https://mudu.tv/show/videolink/oyg23v0r/origin",
    img: "zhitong.png",
    name: "TECH直通车|本期嘉宾：范敦球", },
  {
    videoUrl: "https://mudu.tv/show/videolink/mx2g7v98/origin",
    img: "zhitong.png",
    name: "TECH直通车|本期嘉宾：李晨", },
  {
    videoUrl: "https://mudu.tv/show/videolink/mx2gpz78/origin",
    img: "reai.png",
    name: "TechWorld2021 不负每一份对技术的热爱",
  },
  {
    videoUrl: "https://mudu.tv/show/videolink/mn1vg268/origin",
    img: "jiyu.png",
    name: "TechWorld2021 大咖嘉宾寄语",
  },
  {
    videoUrl: "https://mudu.tv/show/videolink/og9vb8ke/origin",
    img: "bada.png",
    name: "绿盟科技｜八大实验室",
  },
];
export default {
  name: "HotNews",
  components: {
    Title,
  },
  data() {
    return {
      swiper:null,
      articleVisible: false,
      videoVisible: false,
      currentType: "video",
      videoLists: videoList,
      articleLists: [],
      fileList: [],
      currentDetail: {},
      currentVideo: {},
      isShowFileDownload: false,
      currentArticle: {},
      isPc: true,
    };
  },
  created() {
    this.isShowFileDownload= $Config.isShowFileDownload;
    this.init();
  },
  methods: {
    async init() {
      const info = await require.getCategoryArticleList($Config.articleCategoryId);
      const info1 = await require.getCategoryArticleList($Config.fileArticleCategoryId);
      if (info.result == "0") {
        this.articleLists = info.content.items;
      }
      if (info1.result == "0") {
        this.fileLists = info1.content.items;
      }
      this.initSwiper();
    },
    initSwiper() {
      this.$nextTick(() => {
        if (document.body.clientWidth > 750) {
          /* eslint-disable */
          this.isPc=true;
          if(this.currentType=='video'){

            this.swiper=  new Swiper('.pc-video-content', {
              slidesPerView: 3,
              spaceBetween: 10,
              navigation: {
                prevEl: ".next-left",
                nextEl: ".next-right",
              },
            });
          }else if(this.currentType=='article'){
            this.swiper=  new Swiper('.pc-article-content', {
              slidesPerView: 3,
              spaceBetween: 10,
              navigation: {
                    prevEl: ".next-left",
                    nextEl: ".next-right",
              },
            });
          } else {
            this.swiper=  new Swiper('.pc-file-content', {
              slidesPerView: 3,
              spaceBetween: 10,
              navigation: {
                prevEl: ".next-left",
                nextEl: ".next-right",
              },
            });
          }

        } else {
          this.isPc=false;
          /* eslint-disable */
          if(this.currentType=='video'){

            this.swiper=new Swiper('.wap-video-content', {
              slidesPerView: 2,
              spaceBetween: 20,
//            autoplay: 3000,//可选选项，自动滑动
//               loop: true,
              pagination: {
                el: ".wap-video-content-swiper-pagination",
                clickable :true,
              },
            });
          }else if(this.currentType=='article'){
            this.swiper=new Swiper('.wap-article-content', {
              slidesPerView: 2,
              spaceBetween: 20,
//            autoplay: 3000,//可选选项，自动滑动
//               loop: true,
//               pagination: {
//                 el: ".wap-video-content-swiper-pagination",
//               },
            });
          }else {
            this.swiper=new Swiper('.wap-file-content', {
              slidesPerView: 2,
              spaceBetween: 20,
//            autoplay: 3000,//可选选项，自动滑动
//               loop: true,
              pagination: {
                el: ".wap-file-content-swiper-pagination",
                clickable :true,
              },
            });
          }

        }
      });
    },
    closeDialog () {
      this.articleVisible = false;
      this.videoVisible = false;
    },
    async openArticleModal(item){
      this.articleVisible = true;
      this.currentArticle = item;
      const info = await require.getArticleDetail({
        articleId: item.id
      }
      );
      if(info.result=='0'){
       this.currentDetail=info.content;
      }
    },
    openVideoModal(item){
      this.videoVisible = true;
      this.currentVideo = item;
    },
    openFile(item){
      window.open($Config.fileDetailUrl+item.fileIds+'&version=2.0')
    },
    changeNav(type){
      const _this=this;
      // this.swiper?.destroy();
      this.currentType=type;
      if (this.swiper) {
        this.swiper.destroy();
      }
      this.swiper=null;
      _this.initSwiper();

    }
  }
};
</script>

<style scoped></style>
