<template>
  <div class="c-metting-info-box" :class="[wapShow ? 'show' : '']">
    <div
        :class="[wapShow ? 'show-none metting-sidebar wap-mark' : 'show metting-sidebar wap-mark']"
    >
      <div class="left" @click="openSider">
        <div class="left-img">
          <img src="../assets/images/fenluntan-main.png" alt=""/>
        </div>
        <div class="left-text">分论坛直播入口</div>
      </div>
      <div class="right">
        <div
            class="item"
            :class="{ active: checked == i }"
            v-for="(v, i) in lives"
            :key="i"
            @click="checkedFn(v, i)"
        >
          <div v-html="v.wapname"></div>
          <img
              src="../assets/images/qiehuan-right.png"
              alt=""
              v-if="checked == i"
              class="qiehuan-right"
              @click="wapShow = false"
          />
        </div>
      </div>
    </div>
    <div class="metting-left" :class="{ after: mettingStatus == 3 }">
      <div class="c-metting-info">
        <div class="info-line position-line"></div>
        <div class="metting-main">
          <div class="info-top">
            <div class="top-img">
              <img src="@/assets/images/img01.png" alt=""/>
            </div>
            <div class="info-line position-info-line" v-if="mettingStatus != 3"></div>
          </div>
          <div class="info-main">
            <div class="info-tit">
              <div class="en">Introduction to the conference</div>
              <div class="zh-line">
                热爱 · Devotion
                <div class="info-line" v-if="mettingStatus == 3"></div>
              </div>
            </div>
            <div class="info-txt">
              第十届TechWorld技术嘉年华以“热爱 ·
              Devotion”为主题，回归技术本真，传递技术与热爱的核心价值，围绕网络空间安全与技术发展、数字转型与数据安全、云运营与云安全等内容，在金融、教育、运营商等多个行业设立专场、专题分论坛，汇聚资深网络安全行业专家，集合产业发展多方力量，分享新技术、新形势与新探索。
              十年技术老友相会，不负每一份对技术的热爱，让我们共同携手，再次出发。
            </div>
            <div class="info-btn" v-if="mettingStatus == 1||mettingStatus == 2">
              <div class="c-img-btn" @click="openSignUp()" >
                <div class="mb-txt">{{mettingStatus==1?'参会报名':'观看直播'}}</div>
                <div class="mb-icon">>></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="metting-right show" v-if="mettingStatus == 3">
      <div class="left" @click="openSider">
        <div class="left-img">
          <img src="../assets/images/fenluntan-main.png" alt=""/>
        </div>
        <div class="left-text">分论坛直播入口</div>
      </div>
      <div class="right pc-mark">
        <div
            class="item"
            :class="{ active: checked == i ,itemtwo: i == 1}"
            v-for="(v, i,) in lives"
            :key="i"
            @click="checkedFn(v, i)"
        >
          <div v-html="v.name"></div>
          <div class="icon"></div>
          <div class="active-item"></div>

        </div>

      </div>
    </div>
    <SiginUpDialog :signUpShow="signUp" :currentWebinarId="currentWebinarId" :currentUrl="currentUrl" :currentCustomFormId="currentCustomFormId"
                   @close="closeModal($event)"></SiginUpDialog>
  </div>
</template>

<script>
import {signupUrl, tsignupUrl,lives,tlives, xiaWuZhuHui,txiaWuZhuHui,tshangWuZhuHui,shangWuZhuHui} from "@/common/data";
import $config from "../config/config";
import SiginUpDialog from "@/components/SiginUpDialog.vue";
import require from "../services";


export default {
  name: "MettingInfo",
  components: {
    SiginUpDialog
  },
  props: {
    msg: String,
  },
  data() {
    return {
      signupUrl: '',
      status: 2, //1报名2会中直播专场
      lives:'',
      xiaWuZhuHui:'',
      shangWuZhuHui:'',
      checked: null,
      wapShow: true,
      signUp: false, //报名弹框
      phone: "",
      txt: "",
      mettingStatus: "1",
      currentWebinarId: "",
      currentCustomFormId: "",
      currentUrl: "",
      isMobile:  !!navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)
    };
  },
  created() {
    this.mettingStatus =  $config.mettingStatus;
    if(window.location.href.indexOf('local')>-1){
      this.shangWuZhuHui=tshangWuZhuHui;
      this.xiaWuZhuHui=txiaWuZhuHui;
      this.signupUrl=tsignupUrl;
      this.lives=tlives;
    }else {
      this.shangWuZhuHui=shangWuZhuHui;
      this.xiaWuZhuHui=xiaWuZhuHui;
      this.signupUrl=signupUrl;
      this.lives=lives;
    }
  },
  methods: {
    openSider() {
      this.wapShow = !this.wapShow;
    },
   async checkedFn(v, i) {
      this.currentWebinarId = v.webinarId;
      this.currentCustomFormId = v.customFormId;
      this.currentUrl = v.url;
      this.checked = i;
      this.wapShow = false;
      if (!$config.mainIsSignUp) {
        // 没报名
        this.signUp = true;
      } else {
        if($config.mettingStatus == '1'){
          window.open(this.signupUrl);
        }else if ($config.mettingStatus == '3') {
          window.open(this.currentUrl || this.xiaWuZhuHui.url);
        } else {
          window.open(this.shangWuZhuHui.url);
        }

      }
    },
    closeDialog() {
      this.signUp = false;
    },
    async openSignUp() {
      // 会中下午
      if($config.mettingStatus == '1'){
        window.open(this.signupUrl);
      }else {
        if ($config.mettingStatus == '3') {
          this.currentWebinarId = this.xiaWuZhuHui.webinarId;
          this.currentCustomFormId =this.xiaWuZhuHui.customFormId;
        } else {
          this.currentWebinarId = this.shangWuZhuHui.webinarId;
          this.currentCustomFormId = this.shangWuZhuHui.customFormId;
        }
        if (!$config.mainIsSignUp) {
          this.signUp = true;
        } else {
          if($config.mettingStatus == '1'){
            window.open(this.signupUrl);
          }else if ($config.mettingStatus == '3') {
            window.open(this.xiaWuZhuHui.url);
          } else {
            window.open(this.shangWuZhuHui.url);
          }
        }
      }
    },
    closeModal(e) {
      this.signUp = e;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
