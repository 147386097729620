<template>
  <div class="big-view-point">
    <Title :text="'大咖观点'" :tips="'VIEWPOINT'"></Title>
    <div class="big-view-point-content">
      <div class="swiper big-view-point-swiper-container">
        <div class="swiper-wrapper big-wrapper">
          <div class="swiper-slide big-view-item" v-for="(item ,i) in viewPointLists" :key="i">
            <div class="img-box">
              <img :src="require('@/assets/images/' + item.img)" alt="" />
            </div>
            <div class="view-point-info">
              <div class="view-point-name">{{ item.name }}</div>
              <div class="view-point-position">{{ item.position }}</div>
            </div>
            <div class="view-point-viewPoint" v-html="item.viewPoint"></div>
          </div>
        </div>
        <div class="swiper-pagination big-view-point-swiper-pagination"></div>
      </div>
      <div class="pager-nex">
        <div class="next next-left">
          <img class="hot-left" src="../assets/images/hot-left-active.png" alt="" />
        </div>
        <div class="next next-right">
          <img class="hot-right" src="../assets/images/hot-right-active.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "./Title";
import require from "../services";
import Swiper, { Pagination, Navigation } from "swiper";
import {  viewPointLists } from '@/common/data';
Swiper.use([Navigation, Pagination]);
export default {
  name: "BigViewPoint",
  components: {
    Title,
  },
  data() {
    return {
      viewPointLists:'' ,
    };
  },
  created() {
    this.viewPointLists=viewPointLists;
    this.init();
  },
  methods: {
    async init() {
      this.initSwiper();
    },
    initSwiper() {
      this.$nextTick(() => {
        if (document.body.clientWidth > 750) {
          /* eslint-disable */
          this.isPc = true;
          new Swiper('.big-view-point-swiper-container', {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 20,
            navigation: {
              nextEl: ".next-right",
              prevEl: ".next-left"
            },
          });
        }else {
          new Swiper('.big-view-point-swiper-container', {
            slidesPerView: 1.4,
            spaceBetween: 20,
            centeredSlides: true,
            loop: true,
            effect: "creative",
            pagination: {
              el: '.big-view-point-swiper-pagination',
            },
          });
        }
      });
    },
    closeDialog () {
      this.articleVisible = false;
      this.videoVisible = false;
    },
    async openArticleModal (item) {
      this.articleVisible = true;
      this.currentArticle = item;
      const info = await require.getArticleDetail({
          articleId: item.id
        }
      );
      if (info.result == '0') {
        this.currentDetail = info.content;
      }
    },
    openVideoModal (item) {
      this.videoVisible = true;
      this.currentVideo = item;
    },
    changeNav (type) {
      this.currentType = type;
      this.initSwiper();
    }
  }
}
</script>

<style scoped>

</style>
