import request from './request';
import Config from '../config/config';
import $config from "../config/config";
import {getGlobalUserId} from "../utils";

const Api = {
  // 获取文章栏目列表
  getArticleCategoryList(articleCategoryId) {
    const params = {
      tenantId: Config.tenantId,
      articleCategoryId,
      isEnabled: 1,
      moduleId: '',
      instanceId: '',
    };
    return new Promise((resolve) => {
      request
        .getJson(`/${Config.tenantId}/article/category/${articleCategoryId}.json`, {
          baseURL: Config.api.gateway.JsonUrl,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((data) => {
          console.log(data);
          // 接口
          request.postJson('/api/article/category/sub/query', params).then((res) => {
            resolve(res);
          });
        });
    });
  },


  // 获取栏目下文章列表
  getCategoryArticleList(articleCategoryId) {
    const params = {
      tenantId: Config.tenantId,
      instanceId: '',
      articleCategoryId,
      hasChildrenCategory: 0,
      isRecommend: -1,
      isTop: -1,
      isWithStat: 0,
      start: 0,
      num: 50,
      sort: 6,
    };
    return new Promise((resolve) => {
      request
        .getJson(`/${Config.tenantId}/article/list/${articleCategoryId}.json`, {
          baseURL: Config.api.gateway.JsonUrl,
          headers: {'Cache-Control': 'no-cache'},
        })
        .then((res) => {
          resolve(res);
        })
        .catch(() => {
          // 接口
          request.postJson('/api/article/query', params).then((res) => {
            resolve(res);
          });
        });
    });
  },
  getSwebinarInfo(webinarId) {
    const params = {
      tenantId: Config.tenantId,
      webinarId: webinarId,
    };
    return new Promise((resolve) => {
      request
          .getJson(`/${Config.tenantId}/webinar/${webinarId}/info.json`, {
            baseURL: Config.api.gateway.JsonUrl,
            headers: {'Cache-Control': 'no-cache'},
          })
          .then((res) => {
            resolve(res);
          })
          .catch((data) => {
            // 接口
            request.postJson("/api/webinar/get", params).then((res) => {
              resolve(res);
            });
          });
    });
  },
  getContacts(webinarId) {
    const params = {
      tenantId: $config.tenantId,
      webinarId: webinarId,
      sess: '',
      openId: '',
      globalUserId: getGlobalUserId()
    };
    return new Promise((resolve) => {
      request.postJson("/api/webinar/contacts/get", params).then((res) => {
        resolve(res);
      });
    });
  },
  onWebinarQuery(params) {

    return new Promise((resolve) => {
      request.postJson("/api/webinar/query", params).then((res) => {
        resolve(res);
      });
    });
  },
  contactsLogin(param){
    const params = {
      tenantId: $config.tenantId,
      sess: '',
      globalUserId:getGlobalUserId(),
      openId:'',
      openid:'',
      ...param,
    };
    return new Promise((resolve) => {
      request.postJson("/api/webinar/contacts/login", params).then((res) => {
        resolve(res);
      });
    });
  },
  silentSignUp(param){
    const params = {
      globalUserId:getGlobalUserId(),
      ...param
    };
    return new Promise((resolve) => {
      request.postJson("/api/webinar/contacts/signup", params).then((res) => {
        resolve(res);
      });
    });
  },

  // 获取文章详情
  getArticleDetail(param) {
    const params = {
      tenantId: Config.tenantId,
      articleId: '',
      isWithStat: 1, // 是否需要点赞、分享、浏览统计（1：是；0：否）
      isScroll: 0, // 是否需要查询上一篇和下一篇文章id（1：是；0：否）
      isWithTag: 0, // 是否需要标签（1：是；0：否）
      ...param,
    };
    return new Promise((resolve) => {
      request
        .getJson(`/${Config.tenantId}/article/${params.articleId}.json`, {
          baseURL: Config.api.gateway.JsonUrl,
        })
        .then((res) => {
          resolve(res);
        })
        .catch(() => {
          request.postJson('/api/article/get', params).then((res) => {
            resolve(res);
          });
        });
    });
  },
};

export default Api;
