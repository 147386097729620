<template>
  <div>
    <NavComponent></NavComponent>
    <BannerComponent></BannerComponent>
    <VideoList></VideoList>
    <MettingInfo style="display: none"></MettingInfo>
    <MettingAgenda></MettingAgenda>
    <MeetingGuest></MeetingGuest>
    <BigViewPoint v-if="isShowBIgView"></BigViewPoint>
    <MeetingGuide></MeetingGuide>
    <HotNews></HotNews>
    <FooterComponent></FooterComponent>
  </div>
</template>
<script>
import NavComponent from "@/components/Nav.vue";
import BannerComponent from "@/components/Banner.vue";
import MettingInfo from "@/components/Info.vue";
import HotNews from "@/components/HotNews.vue";
import MettingAgenda from "@/components/Agenda.vue";
import FooterComponent from "@/components/Footer.vue";
import MeetingGuide from "@/components/Guide.vue";
import BigViewPoint from "@/components/BigViewPoint.vue";
import MeetingGuest from "@/components/Guest.vue";
import VideoList from "@/components/VideoList.vue";
import $config from "../config/config";
export default {
  name: "HomeView",
  components: {
    MettingAgenda,
    MettingInfo,
    BannerComponent,
    NavComponent,
    HotNews,
    MeetingGuide,
    BigViewPoint,
    MeetingGuest,
    VideoList,
    FooterComponent,
  },
  data() {
    return {
      mettingStatus: "",
      isShowBIgView: false,
    };
  },
  created() {
    this.mettingStatus = $config.mettingStatus;
    this.isShowBIgView = $config.isShowBIgView;
  },
};
</script>
