<template>
  <div class="sk-loading">
    <div class="sk-loading-wrap">
      <div class="sk-loading-row">
        <div class="sk-loading-spin">
          <span class="sk-loading-item"></span>
          <span class="sk-loading-item"></span>
          <span class="sk-loading-item"></span>
          <span class="sk-loading-item"></span>

        </div>
      </div>
      <div class="sk-loading-text">Loading...</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading"
}
</script>

<style scoped>

</style>
