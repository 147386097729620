<template>
  <header class="c-header">
    <div class="header-logo" @click="setScroll('')">
      <img src="@/assets/images/logo.png" class="pc-mark" />
      <img src="@/assets/images/logo01.png" class="wap-mark" />
    </div>
    <div class="header-right">
      <div class="nav-opea wap-mark" :class="{ show: isShow }" @click="setShow(1)">
        <img src="@/assets/images/img03.png" />
      </div>
      <nav class="c-nav" :class="{ show: isShow }" @click.stop="setShow(0)">
        <div class="nav-close wap-mark" @click.stop="setShow(0)">
          <img src="@/assets/images/img04.png" alt="" />
        </div>
        <ul class="nav-list">
          <li class="nav-item active wap-mark" @click="openSignUp()" v-if="mettingStatus=='1'||mettingStatus=='2'||mettingStatus=='3'">
            <a class="nav-link">{{mettingStatus==1?'参会报名':'观看直播'}}</a>
          </li>
          <li class="nav-item" @click="setScroll('.videolist')" v-if="mettingStatus=='5'">
            <a class="nav-link" >精彩视频</a>
          </li>
          <li class="nav-item" @click="setScroll('.c-metting-info-box')" v-if="mettingStatus=='1'||mettingStatus=='2'||mettingStatus=='3'">
            <a class="nav-link">大会介绍</a>
          </li>
          <li class="nav-item" @click="setScroll('.c-agenda')">
            <a class="nav-link">会议日程</a>
          </li>
          <li class="nav-item" @click="setScroll('.c-guest')">
            <a class="nav-link">重磅嘉宾</a>
          </li>
          <li class="nav-item" @click="setScroll('.big-view-point')" v-if="isShowBIgView">
            <a class="nav-link">大咖观点</a>
          </li>
          <li class="nav-item" @click="setScroll('.c-guide')">
            <a class="nav-link">参会指南</a>
          </li>
          <li class="nav-item" @click="setScroll('.hotNews')">
            <a class="nav-link">热门资讯</a>
          </li>
        </ul>
        <div class="nav-mask wap-mark"></div>
      </nav>
      <div class="c-img-btn pc-mark" @click="openSignUp()" v-if="mettingStatus=='1'||mettingStatus=='2'||mettingStatus=='3'">
        <div class="mb-img">
          <img src="@/assets/images/btnicon01.png" />
        </div>
        <div class="mb-txt">{{mettingStatus==1?'参会报名':'观看直播'}}</div>
      </div>
    </div>
    <SiginUpDialog :signUpShow="signUp" :currentWebinarId="currentWebinarId" :currentCustomFormId="currentCustomFormId" @close="closeModal($event)"></SiginUpDialog>
  </header>
</template>

<script >
import SiginUpDialog from "@/components/SiginUpDialog.vue";
import $ from 'jquery';
import {signupUrl,tsignupUrl, shangWuZhuHui,tshangWuZhuHui, txiaWuZhuHui,xiaWuZhuHui} from "@/common/data";
import $config from '../config/config';
import require from "../services";
export default {
  name: 'NavComponent',
  props: {
    msg: String,
  },
  components: {
    SiginUpDialog
  },
  data() {
    return {
      isShow: false,
      signUp: false,
      signupUrl: '',
      xiaWuZhuHui:'',
      shangWuZhuHui:'',
      mettingStatus:'',
      currentWebinarId:'',
      currentCustomFormId:'',
      isShowBIgView:false,
    };
  },
  created(){
    const _this=this;
    // document.onclick = function () {
    //   _this.isShow = false;
    // };
    this.mettingStatus=$config.mettingStatus;
    this.isShowBIgView=$config.isShowBIgView;
    if(window.location.href.indexOf('local')>-1){
      this.shangWuZhuHui=tshangWuZhuHui;
      this.xiaWuZhuHui=txiaWuZhuHui;
      this.signupUrl=tsignupUrl;
    }else {
      this.shangWuZhuHui=shangWuZhuHui;
      this.xiaWuZhuHui=xiaWuZhuHui;
      this.signupUrl=signupUrl;
    }
  },
  methods: {
   async openSignUp() {
     if($config.mettingStatus == '1'){
       window.open(this.signupUrl);
     }else {
       if ($config.mettingStatus == '3') {
         this.currentWebinarId = this.xiaWuZhuHui.webinarId;
         this.currentCustomFormId = this.xiaWuZhuHui.customFormId;
       } else {
         this.currentWebinarId = this.shangWuZhuHui.webinarId;
         this.currentCustomFormId = this.shangWuZhuHui.customFormId;
       }
       if(!$config.mainIsSignUp){
         this.signUp = true;
       }else {
         if($config.mettingStatus == '1'){
           window.open(this.signupUrl);
         }else if ($config.mettingStatus == '3') {
           window.open(this.xiaWuZhuHui.url);
         } else {
           window.open(this.shangWuZhuHui.url);
         }
       }
     }
    },
    setShow(n) {
      this.isShow = n === 1;
    },
    setScroll(s) {
     console.log();
     const heardH=document.getElementsByClassName("c-header")[0].clientHeight;
      const t = s ? $(s).offset()?.top : 0;
      $('html, body').animate({
        scrollTop: t-heardH,
      });
      this.isShow = false;
    },
    closeModal(e){
      this.signUp=e;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
