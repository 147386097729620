import Cookies from 'js-cookie';
/* eslint-disable */
/*获取getGlobalUserId*/
export function getGlobalUserId() {
  if (Cookies.get('globalUserId')) {
    try {
      return JSON.parse(Cookies.get('globalUserId'));
    } catch (e) {
      console.log("clear");
    }
  }
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
  uuid = uuid.replace(/-/g, '');
  Cookies.set('globalUserId', JSON.stringify(uuid), {
    expires: 365,
    domain: '.smarket.net.cn'
  });
  return uuid;
}

/*获取地址栏参数*/
export function getUrlKey(name) {
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null;
}

/*添加或者更新uri中的参数*/
export function updateParam(key, value, uri) {

  // Use window URL if no query string is provided
  if (!uri) {
    uri = window.location.href;
  }

  // Create a dummy element to parse the URI with
  var a = document.createElement('a'),

    // match the key, optional square bracktes, an equals sign or end of string, the optional value
    reg_ex = new RegExp(key + '((?:\\[[^\\]]*\\])?)(=|$)(.*)'),

    // Setup some additional variables
    qs,
    qs_len,
    key_found = false;

  // Use the JS API to parse the URI
  a.href = uri;

  // If the URI doesn't have a query string, add it and return
  if (!a.search) {

    a.search = '?' + key + '=' + value;

    return a.href;
  }

  // Split the query string by ampersands
  qs = a.search.replace(/^\?/, '').split(/&(?:amp;)?/);
  qs_len = qs.length;

  // Loop through each query string part
  while (qs_len > 0) {

    qs_len--;

    // Check if the current part matches our key
    if (reg_ex.test(qs[qs_len])) {

      // Replace the current value
      qs[qs_len] = qs[qs_len].replace(reg_ex, key + '$1') + '=' + value;

      key_found = true;
    }
  }

  // If we haven't replaced any occurences above, add the new parameter and value
  if (!key_found) {
    qs.push(key + '=' + value);
  }

  // Set the new query string
  a.search = '?' + qs.join('&');

  return a.href;
}



/*是否是微信浏览器*/
export function isWechatBrowser() {
  return window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == 'micromessenger';
}

/*日期时间格式化*/
export function formatDateTime(dt, fmt) {
  let o = {
    "M+": dt.getMonth() + 1,                 //月份
    "d+": dt.getDate(),                    //日
    "h+": dt.getHours(),                   //小时
    "m+": dt.getMinutes(),                 //分
    "s+": dt.getSeconds(),                 //秒
    "q+": Math.floor((dt.getMonth() + 3) / 3), //季度
    "S": dt.getMilliseconds()             //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (dt.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}

/*htmlEncodeByRegExp*/
export function htmlEncodeByRegExp(str) {
  var s = "";
  if (str.length == 0) return "";
  s = str.replace(/</g, "");
  s = s.replace(/>/g, "");
  s = s.replace(/'/g, "");
  s = s.replace(/"/g, "");
  s = s.replace(/javascript/g, "");
  s = s.replace(/alert/g, "");
  return s;
}

/**
 * 一维数组转二维数组
 * @param arr
 * @param len
 * @returns {[]|*[]}
 */
export function splitArr(arr, len) {
  if (!Array.isArray(arr)) return [];

  if (!len) {
    // throw new Error('Please confirm the separation step');
    return [];
  }

  let targetLen = arr.length, i = 0, res = [];

  if (targetLen === 0) {
    return [];
  }

  while (targetLen && targetLen % len >= 0) {
    res = [...res, ...[arr.slice(i * len, (i + 1) * len)]];
    i++;
    targetLen -= len;
  }

  return res;
}

export function debounce(func, wait, options = {}) {
  let timeout
  const result = {}
  return function () {
    const context = this
    const args = arguments
    const later = function () {
      timeout = null
      if (!options.immediate) {
        if (options.async) {
          const res = func.apply(context, args)
          if (typeof res.then === 'function') {
            res.then(tar => result.resolve(tar))
          }
        } else {
          func.apply(context, args)
        }
      }
    }
    const callNow = options.immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
    if (options.async) {
      return new Promise((resolve) => {
        result.resolve = resolve
      })
    }
  }
}
