export const signupUrl = "https://uao.so/pctc83e0bc";
export const tsignupUrl = "https://uao.so/pctc4d6572";
export const agendaList = [
  [
    {
      time: "09:30-09:40",
      title: "主办方领导致辞",
      user: "",
      job: ["绿盟科技集团领导"],
      times: [""],
    },
    {
      time: "09:40-10:00",
      title: "云原生时代安全能力体系构建",
      user: "叶晓虎",
      job: ["绿盟科技集团首席技术官"],
    },
    {
      time: "10:00-10:20",
      title: "政府公共数据开放共享的安全风险和挑战",
      user: "李新友",
      job: ["国家信息中心首席工程师"],
    },
    {
      time: "10:20-10:40",
      title: "后疫情时代的安全技术创新",
      user: "刘文懋",
      job: ["绿盟科技集团首席创新官"],
    },
    {
      time: "10:40-11:00",
      title: "中外云安全市场发展趋势解读，一样的云，不一样的安全",
      user: "王军民",
      job: ["IDC咨询有限公司研究总监"],
    },
    {
      time: "11:00-11:20",
      title: "攻防对抗视角下的俄乌网络战",
      user: "范敦球",
      job: ["绿盟科技集团能力中心总经理"],
    },
    {
      time: "11:20-11:40",
      title: "对企业数据安全合规体系建设的思考",
      user: "陈湉",
      job: ["中国信息通信研究院数据安全事业部主任"],
    },
    {
      time: "11:40-12:00",
      title: "数据安全实验室签约仪式",
      user: "",
      job: ["中国信息通信研究院&绿盟科技集团"],
    },
  ],
  [
    {
      time: "14:00-14:10",
      title: "致辞",
      user: "宫智",
      job: ["绿盟科技集团副总裁"],
    },
    {
      time: "14:10-14:35",
      title: "探索完善数据安全制度，协力推进数据要素流通",
      user: "林星辰",
      job: ["国家计算机网络应急技术处理协调中心高级工程师"],
    },
    {
      time: "14:35-15:00",
      title: "数据安全治理思路分享",
      user: "张成刚",
      job: ["国家电投集团科学技术研究院总体技术部主任"],
    },
    {
      time: "15:00-15:25",
      title: "关于数据安全的一些思考和建议",
      user: "黄玉钏",
      job: ["应急管理部大数据中心信息技术实验室副主任"],
    },

    {
      time: "15:35-16:00",
      title: "5G网络数据安全防护思路分享",
      user: "江为强",
      job: ["中国移动通信集团信安中心高级专家、博士"],
    },
    {
      time: "16:00-16:25",
      title: "数据平台的发展与安全防护思考",
      user: "刘弘利",
      job: ["绿盟科技集团解决方案中心负责人"],
    },
    {
      time: "16:25-16:50",
      title: "交通运输领域数据安全管理与防护思考",
      user: "周国强",
      job: ["交通运输部规划研究院信息所主任工程师"],
    },
  ],
  [
    {
      time: "14:00-14:10",
      title: "领导致辞",
      user: "潘润红",
      job: ["中国金融电子化集团有限公司副总经理"],
    },
    {
      time: "14:10-14:20",
      title: "领导致辞",
      user: "李晨",
      job: ["绿盟科技集团副总裁"],
    },
    {
      time: "14:20-14:40",
      title: "商业银行数据安全与隐私计算实践",
      user: "陈德锋",
      job: ["中国建设银行股份有限公司金融科技部安全架构管理处负责人"],
    },
    {
      time: "14:40-15:00",
      title: "数字化转型背景下的业务安全能力建设实践",
      user: "魏巍",
      job: ["中国民生银行股份有限公司信息科技部安全规划中心高级经理"],
    },
    {
      time: "15:00-15:20",
      title: "构建数据化网络安全运营平台",
      user: "蒋斌",
      job: ["华夏银行股份有限公司信息科技部科技安全与风险管理室副经理"],
    },

    {
      time: "15:30-15:50",
      title: "金融科技背景下的商业银行信息安全工作实践",
      user: "王贵智",
      job: ["工银科技有限公司安全总监"],
    },
    {
      time: "15:50-16:10",
      title: "密码筑牢网络安全防线 构建数据安全保障体系",
      user: "王天昊",
      job: ["国家金融科技测评中心（银行卡检测中心）高级密码安全专家"],
    },
    {
      time: "16:10-16:30",
      title: "激活金融数字化潜能 筑牢数据安全“新防线”",
      user: "陈怀源",
      job: ["绿盟科技集团数据安全架构师"],
    },
    {
      time: "16:30-16:50",
      title: "操作系统安全防护体系及实践",
      user: "张鹏",
      job: ["统信软件技术有限公司资深产品市场专家"],
    },
  ],
  [
    {
      time: "14:00-14:05",
      title: "领导致辞",
      user: "孙冀平",
      job: ["绿盟科技集团副总裁"],
    },
    {
      time: "14:05-14:10",
      title: "领导致辞",
      user: "封化民",
      job: ["教育部高等学校网络空间安全专业教学指导委员会秘书长"],
    },
    {
      time: "14:10-14:35",
      title: "产学研协同创新理念与思考",
      user: "李永松",
      job: ["绿盟科技科教文卫事业部总经理"],
    },
    {
      time: "14:35-15:00",
      title: "谋篇布局打造一流网络空间安全学科</br>真抓实干建设一流网络空间安全学院",
      user: "蒋燕玲",
      job: ["北京航空航天大学网络空间安全学院党委书记"],
    },
    {
      time: "15:00-15:25",
      title: "国家微生物科学数据中心大数据支撑的微生物资源挖掘利用",
      user: "马俊才",
      job: ["国家微生物科学数据中心主任"],
    },
    {
      time: "15:35-16:00",
      title: "创新实践与校企协同优秀案例分享",
      user: "邹德清",
      job: ["华中科技大学网络空间安全学院常务副院长"],
    },
    {
      time: "16:00-16:25",
      title: "工控系统安全测试及攻防验证平台",
      user: "孙彦斌",
      job: ["广州大学网络安全学院网络安全研究所所长"],
    },
    {
      time: "16:25-16:50",
      title: "产学研合作的创新实验室实践",
      user: "刘文懋",
      job: ["绿盟科技集团首席创新官"],
    },
  ],
];
export  const viewPointLists= [
  //主论坛
  {
    img: "big-view-point/yxh.png",
    name: "叶晓虎",
    position: "绿盟科技集团首席技术官",
    viewPoint:
        "2022年绿盟科技在智慧安全3.0基础上提出构建弹性、异构、按需服务的安全能力体系架构，发布了T-One Cloud解决方案，拉通安全产品和服务，通过可信连接把安全能力传递到客户处，形成云化交付的安全运营体系，建设云端智慧大脑，八大实验室研究成果持续赋能，进一步深化绿盟科技战略转型。",
  },
  {
    img: "big-view-point/lxy.png",
    name: "李新友",
    position: "国家信息中心首席工程师 ",
    viewPoint:
        "近年来，我国政府逐步从“政府信息公开”向“政府数据开放”探索前进，各地政务数据向社会公众开放的进程逐步加快。随着新兴技术快速发展、应用场景迅速扩展、安全形势不断变化，在数据成为社会发展关键变量的同时，政务数据作为重要的生产要素，也面临着安全挑战，因此在促进数据开放与共享的同时，更要高度重视对数据的保护。",
  },
  {
    img: "big-view-point/lwm.png",
    name: "刘文懋",
    position: "绿盟科技集团首席创新官",
    viewPoint:
        "后疫情时代的云化、人工智能和数据流转成为数字化转型的新趋势，如何保护这些新技术新场景，又如何利用新技术赋能“智慧安全3.0”，我们分别介绍了攻防驱动的云安全、云化战略下的智慧安全大脑，以及赋能数据安全流转的隐私增强计算平台，这些创新实践将贴近用户实际，解决真实问题。",
  },
  {
    img: "big-view-point/wjm.png",
    name: "王军民",
    position: "IDC咨询有限公司研究总监",
    viewPoint:"伴随着云基础设施的大规模采用，中国的公有云、行业云市场得到了快速的发展。企业上云后的IT环境变成混合云、多云架构，其网络暴露面变大，安全管理难度也在加大，在这样的背景下，云安全成为各行各业最为关注的焦点之一。在市场强烈需求的驱动下，中国公有云安全市场规模在安全软件市场中已经接近50%。中国的云安全市场与全球发达国家相比，有共性也有明显的差异。<br>" +
        "本次分享将通过IDC对云安全市场的现状及展望，为企业构建云安全体系提供第三方视角下的参考。",
  },
  {
    img: "big-view-point/fdq.png",
    name: "范敦球",
    position: "绿盟科技集团能力中心总经理",
    viewPoint:
        "尝试通过攻防视角解读俄乌网络战的态势和技术特点，结合大国网络安全体系化建设的要求，思考绿盟科技如何更好的服务于国家和企业的网络安全现代化建设。",
  },
  {
    img: "big-view-point/ct.png",
    name: "陈湉",
    position: "中国信息通信研究院 数据安全事业部主任",
    viewPoint:
        "数据安全“强监管”时代背景下，企业如何应对数据安全合规挑战，如何强化数据安全保障能力？资源投入、业务发展、合规落地三方面找寻平衡点是关键。“数据安全合规体系建设方案”给出了建设覆盖全面、上下联动、统一协调的数据安全合规体系的方法和路径，针对企业核心问题，帮助企业达到提升数据安全风险防范能力的目标。",
  },
  //创新派
  {
    img: "big-view-point/lxc.png",
    name: "林星辰",
    position: "国家计算机网络应急技术处理协调中心高级工程师",
    viewPoint:
        "近年来，我国的数据安全产业顶层设计思路逐渐明晰，数据安全法律法规相继出台，数据安全风险评估工作也在高效推进。\n" +
        " 作为承上启下、识别风险、发现风险的关键环节，数据安全风险评估对应急处置、安全审查有着不可或缺的作用。我国正处于数据安全产业发展的关键时期，数据安全保护规则体系需要进一步细化，构建全方位涵盖技术、合规、标准、实践的数据安全制度，有助于真正建立起安全、有序、充满活力的数据要素流动秩序。\n",
  },
  {
    img: "big-view-point/zcg.png",
    name: "张成刚",
    position: "国家电投集团科学技术研究院有限公司处长（国家电投集团科学技术研究院总体技术部主任 ）  ",
    viewPoint:
        "本次演讲，聚焦以下三个方面： </br>" +
        "1、如何理解构建一源一数体系</br>" +
        "2、如何管理好相关数据</br>" +
        "3、打造一体化数据运营管理体系，支撑数字化转型实现",
  },
  {
    img: "big-view-point/jwq.png",
    name: "江为强",
    position: "中国移动集团信安中心副处级高级专家、高级工程师、博士",
    viewPoint:
        "随着5G网络与业务的快速发展，5G网络环境中个人和组织的数据安全风险将会面临更严峻的挑战。本议题分析了5G环境下的数据安全风险，汇总了当前国家行业在5G数据安全监管、标准上的相关要求与指南。提出了5G数据安全防护框架，并从5G业务、5G终端、无线接入、5G核心网等多个方面提出数据安全防护措施，同时，介绍了5G消息、MEC、算力网络等5G关键领域数据安全举措，以及5G+工业互联网、智慧医疗、电网等5G典型行业数据安全案例，为行业内5G数据安全治理工作提供参考。",
  },
  {
    img: "big-view-point/zgq.png",
    name: "周国强",
    position: "交通运输部规划研究院主任工程师",
    viewPoint:
        "交通运输是贯彻国家数据分类分级保护制度、加强重要数据保护的重点行业之一。近年来，交通运输部通过开展政策研究、出台有关政策文件，有效加强了交通运输数据安全管理和保护。本议题从研究视角对贯彻国家有关文件、交通运输行业实践、未来行业发展建议等3方面进行了阐述与分享，以期共同推动行业数据安全治理水平提升。",
  },
  //实战派
  {
    img: "big-view-point/cdf.png",
    name: "陈德锋",
    position: "中国建设银行金融科技部安全架构管理处",
    viewPoint:
        "面向建行业务应用需求，按照“平台化、场景化、标准化、生态化”的思路，进行技术研究、平台建设、场景落地，打造企业级数据共享安全计算平台，平台在保护各方隐私的前提下，实现联合查询、联合运算、联合建模多种核心数据融合功能，支持机构间的数据隐私共享及不同金融场景的应用建模，实现数据的可用不可见，激活数据价值，助力业务数字化发展。",
  },
  {
    img: "big-view-point/jb.png",
    name: "蒋斌",
    position: "华夏银行信息技术部安全处处长  ",
    viewPoint:
        "建设以数字化安全运营平台为核心的网络安全监测防护体系，增强网络安全风险发现、处置效率，提高态势感知、通报预警和分析处置能力。通过平台建设，实施日志告警数据统一收集、标准处理、关联分析，以及各类安全设施接口化改造，深化机器学习、知识图谱、自动化响应与编排等技术应用，构建起一套标准化、松耦合、可弹性扩展的数字化网络安全运营平台，网络安全综合防护水平得到有效提升。",
  },
  {
    img: "big-view-point/wgz.png",
    name: "王贵智",
    position: "工银科技有限公司安全总监",
    viewPoint:
        "站在商业银行安全工作从业者视角，分析金融科技给商业银行信息安全工作带来的影响，分享介绍工商银行在信息安全工作方面的实践。",
  },
  {
    img: "big-view-point/wth.png",
    name: "王天昊",
    position: "国家金融科技测评中心 高级密码安全专家  ",
    viewPoint:
        "以商用密码为核心，为数据安全保驾护航。介绍商用密码在《数据安全法》和各行业中的要求，结合商用密码改造在金融行业从系统前后端各链条的密码应用进行阐述。",
  },
//学院派
  {
    img: "big-view-point/lys.png",
    name: "李永松",
    position: "科教文卫事业部总经理\n" +
        "绿盟网络空间安全学院副院长\n",
    viewPoint:
        "数字化时代已来，网络安全面临来自包括核心技术、精英人才培养等方面的多重挑战。绿盟科技立足国家战略需求，依托多年网络安全实战经验与技术积累，结合院校办学特色，围绕“产、学、研”，携手院校共同走出了网安人才的特色培养之路。绿盟科技将与广大院校形成合力，共同探索产学合作、协同育人新方式、新途径，加快推动对前沿领域、技术的研究与创新，助力科研技术成果的产出与转化，全面提升我国网络空间安全水平。",
  },
  {
    img: "big-view-point/tzh.png",
    name: " 田志宏",
    position: "广州大学网络空间安全学院院长",
    viewPoint:
        "我国正处于工业信息化转型阶段，即在完成两化融合的基础上构建新型工业生态的过程。网络安全技术在其中的应用也从单纯保障网络转变为对生产、运营、产业、供应链等一系列生态的保障。报告介绍了在工控安全靶场方面的一些研究成果，利用工控靶场对电力，冶金，石化等场景进行复现，并研究其安全性。",
  },
  {
    img: "big-view-point/ljw.png",
    name: " 刘建伟",
    position: "北京航空航天大学网络空间安全学院院长",
    viewPoint:
        "随着空天信息网络的快速建设和发展，空天信息网络的安全问题已经成为国内外的一个研究热点。本学术报告首先介绍了空天信息网络的定义，分析了该网络与现有网络的显著差异及特点；第二，提出了空天信息网络所面临的主要安全问题与安全挑战，给出了针对该网络的一些典型的攻击方法；第三，综述了空天信息网络安全的研究现状与发展动态，介绍了国内外在空天信息网络安全领域的研究进展情况。" ,
  },
  {
    img: "big-view-point/zdq.png",
    name: " 邹德清",
    position: "华中科技大学网络空间安全学院常务副院长",
    viewPoint:
        "近年来软件安全事件频频发生，软件复杂性日益突出，如何面向海量应用深刻剖析软件安全成因、实施精准高效的安全检测与防护成为新的挑战。报告人所在团队通过深入调研软件安全问题，提出了基于张量的二进制代码嵌入、基于联邦学习的代码语义特征提取、基于深度学习的软件漏洞智能检测与定位、基于内存访问虚拟化的软件栈溢出漏洞自动诊断和修复、基于虚拟机镜像划分的离线升级等关键技术和方法，实现了软件安全科技成果在多个行业信息化建设中的广泛应用。",
  },
];
// test 上午的主会
export  const tshangWuZhuHui= {
  webinarId: "123426",
  name: "",
  customFormId: "2208163688221387",
  url: "https://uao.so/pctm818940",
};
// test 下午的主会
export  const txiaWuZhuHui= {
      webinarId: "123427",
      name: "“创新派”数据安全治理与防护论坛",
      customFormId: "2208164251830025",
      url: "https://uao.so/pctm818942",
    };
// 正式 上午的主会
export  const shangWuZhuHui= {
  webinarId: "122097",
  name: "",
  customFormId: "2207252664758209",
  url: "https://uao.so/pctm808553",
};
// 正式 下午的主会
export  const xiaWuZhuHui= {
  webinarId: "122225",
  name: "“创新派”数据安全治理与防护论坛",
  customFormId: "2207273804003036",
  url: "https://uao.so/pctm809661",
};

// test 的直播列表
export  const tlives = [
  {
    webinarId: "123427",
    name: "“创新派”数据安全<br>治理与防护论坛",
    wapname:"\"创新派\"<br>数据安全治理与防护论坛",
    customFormId: "2208164251830025",
    url: "https://uao.so/pctm818942",
  },
  {
    webinarId: "123428",
    name: "“实战派”金融数字化<br>转型论坛",
    wapname: "\"实战派\"<br>金融数字化转型论坛",
    customFormId: "2208164262107786",
    url: "https://uao.so/pctm818944",
  },
  {
    webinarId: "123429",
    name: "“学院派”产学研创新<br>融合论坛",
    wapname: "\"学院派\"<br>产学研创新融合论坛",
    customFormId: "2208160408490240",
    url: "https://uao.so/pctm818946",
  },
];
// 正式 的直播列表
export  const lives = [
  {
    webinarId: "122225",
    name: "“创新派”数据安全<br>治理与防护论坛",
    wapname:"\"创新派\"<br>数据安全治理与防护论坛",
    customFormId: "2207273804003036",
    url: "https://uao.so/pctm809661",
  },
  {
    webinarId: "122226",
    name: "“实战派”金融数字化<br>转型论坛",
    wapname: "\"实战派\"<br>金融数字化转型论坛",
    customFormId: "2207270300676671",
    url: "https://uao.so/pctm809663",
  },
  {
    webinarId: "122227",
    name: "“学院派”产学研创新<br>融合论坛",
    wapname: "\"学院派\"<br>产学研创新融合论坛",
    customFormId: "2207270759863585",
    url: "https://uao.so/pctm809665",
  },
];

//嘉宾
export const guestsList=[{
  img: "./img/guest01.png",
  name: "叶晓虎",
  company: "绿盟科技集团",
  job: "首席技术官",
  index: 1,
},{
  img: "./img/guest02.png",
  name: "李新友",
  company: "国家信息中心",
  job: "首席工程师",
  index: 2,
},{
  img: "./img/guest03.png",
  name: "刘文懋",
  company: "绿盟科技集团",
  job: "首席创新官",
  index: 3,
},{
  img: "./img/guest04.png",
  name: "王军民",
  company: "IDC咨询有限公司",
  job: "研究总监",
  index: 4,
},{
  img: "./img/guest05.png",
  name: "范敦球",
  company: "绿盟科技集团能力中心",
  job: "总经理",
  index: 5,
},{
  img: "./img/guest06.png",
  name: "陈湉",
  company: "中国信息通信研究院安全研究所",
  job: "数据安全事业部主任",
  index: 6,
},{
  img: "./img/guest07.png",
  name: "李永松",
  company: "绿盟科技科教文卫本部",
  job: "总经理",
  index: 7,
},{
    img: "./img/guest08.png",
    name: "田志宏",
    company: "广州大学网络空间安全学院",
    job: "院长",
    index: 8,
  },{
  img: "./img/guest09.png",
  name: "刘建伟",
  company: "北京航空航天大学网络空间安全",
  job: "学院院长",
  index: 9,
},{
  img: "./img/guest10.png",
  name: "邹德清",
  company: "华中科技大学网络空间安全学院",
  job: "常务副院长",
  index: 10,
}]

export const agendaTimes = [
  "2022年 8月19日 09:30-12:00",
  "2022年 8月19日 14:00-17:00",
  "2022年 8月19日 14:00-17:00",
  "2022年 8月19日 14:00-16:50",
];
export const mainForums=[
  {
    videoUrl: "https://mudu.tv/show/videolink/mn1vk728/origin",
    img: "zlt/yxh.png",
    name: "云原生时代安全能力体系构建-叶晓虎",
  },
  // {
  //   videoUrl: "https://mudu.tv/show/videolink/ma6xvz50/origin",
  //   img: "zlt/lxy.png",
  //   name: "政府公共数据开放共享的安全风险和挑战-李新友",
  // },
  {
    videoUrl: "https://mudu.tv/show/videolink/l8p051jv/origin",
    img: "zlt/lwm.png",
    name: "后疫情时代的安全技术创新-刘文懋",
  },
  {
    videoUrl: "https://mudu.tv/show/videolink/mpd9kj6n/origin",
    img: "zlt/wjm.png",
    name: "中外云安全市场发展趋势解读，一样的云，不一样的安全-王军民",
  },
  {
    videoUrl: "https://mudu.tv/show/videolink/oyg27rzy/origin",
    img: "zlt/fdq.png",
    name: "攻防对抗视角下的俄乌网络战-范敦球",
  },
]
export const dataForums=[
  {
    videoUrl: "https://mudu.tv/show/videolink/lj8vd95d/origin",
    img: "cxp/zcg.png",
    name: "数据安全治理思路分享-张成刚",
  },
  {
    videoUrl: "https://mudu.tv/show/videolink/o92v9gjn/origin",
    img: "cxp/5G.jpg",
    name: "5G网络数据安全防护思路分享-江为强",
  },
  {
    videoUrl: "https://mudu.tv/show/videolink/m26dk15d/origin",
    img: "cxp/lhl.png",
    name: "数据平台的发展与安全防护思考-刘弘利",
  },
]
export const financeForums=[
  {
    videoUrl: "https://mudu.tv/show/videolink/mx2gnkja/origin",
    img: "szp/wth.png",
    name: "密码筑牢网络安全防线构建数据安全保障体系-王天昊",
  },
  {
    videoUrl: "https://mudu.tv/show/videolink/mzxa389n/origin",
    img: "szp/chy.png",
    name: "激活金融数字化潜能 筑牢数据安全“新防线”-陈怀源",
  },
]
export const educationForums=[
  {
    videoUrl: "https://mudu.tv/show/videolink/mn1vk6g5/origin",
    img: "xyp/lys.png",
    name: "产学研协同创新理念与思考-李永松",
  },
  {
    videoUrl: "https://mudu.tv/show/videolink/mn1vk6gx/origin",
    img: "xyp/lwm.png",
    name: "产学研合作的创新实验室实践-刘文懋",
  },
]



