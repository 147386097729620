<template>
  <div class="c-guide">
    <div class="guide-left">
      <c-title :text="'参会指南'" :tips="'GUIDE'" :type="1"></c-title>
      <div class="left-bg">
        <img src="@/assets/images/bg06.png" alt="" />
      </div>
    </div>
    <div class="guide-right">
      <div class="guide-items" @click="openCurrenModal(siteInfo)">
        <div class="item-image">
          <img :src="siteInfo.coverImageRealPath" alt="" />
        </div>
        <div class="item-content">
          <div class="item-icon">
            <img src="@/assets/images/icon01.png" alt="" />
          </div>
          <div class="item-text">
            <div class="item-tit">{{ siteInfo.newTitle }}</div>
            <div class="item-txt">{{ siteInfo.newTip }}</div>
          </div>
        </div>
        <div class="item-mask">
          <img src="@/assets/images/icon04.png" alt="" />
        </div>
      </div>
      <div class="guide-items" @click="openCurrenModal(trafficInfo)">
        <div class="item-image">
          <img :src="trafficInfo.coverImageRealPath" alt="" />
        </div>
        <div class="item-content">
          <div class="item-icon">
            <img src="@/assets/images/icon02.png" alt="" />
          </div>
          <div class="item-text">
            <div class="item-tit">{{ trafficInfo.newTitle }}</div>
            <div class="item-txt">{{ trafficInfo.newTip }}</div>
          </div>
        </div>
        <div class="item-mask">
          <img src="@/assets/images/icon04.png" alt="" />
        </div>
      </div>
      <div class="guide-items" @click="openCurrenModal(epodemicInfo)">
        <div class="item-image">
          <img :src="epodemicInfo.coverImageRealPath" alt="" />
        </div>
        <div class="item-content">
          <div class="item-icon">
            <img src="@/assets/images/icon03.png" alt="" />
          </div>
          <div class="item-text">
            <div class="item-tit">{{ epodemicInfo.newTitle }}</div>
            <div class="item-txt">{{ epodemicInfo.newTip }}</div>
          </div>
        </div>
        <div class="item-mask">
          <img src="@/assets/images/icon06.png" alt="" />
        </div>
      </div>
    </div>

    <div class="pc-article-box" v-if="showPcModal">
      <div class="pc-article-box__body">
        <div class="close" @click="closeDialog">
          <img src="../assets/images/articleClose.png" alt="" />
        </div>
        <div class="pc-article-box__content">
          <div class="article-title">{{ currentDetail.newTitle }}</div>
          <div class="article-content">
            <div v-html="currentDetail.content">

            </div>
<!--            <div class="left-text">{{ currentDetail.summary }}</div>-->
<!--            <div class="left-right"><img :src="currentDetail.coverImageRealPath" alt="" /></div>-->
          </div>
        </div>
      </div>
    </div>
    <div class="article-box" v-if="showWapModal">
      <div class="article-box__body">
        <div class="close" @click="closeDialog">
          <img src="../assets/images/articleClose.png" alt="" />
        </div>
        <div class="article-box__content">
          <div class="article-title">{{ currentDetail.newTitle }}</div>
          <div class="article-content">
            <div v-html="currentDetail.content">

            </div>
<!--            <div><img :src="currentDetail.coverImageRealPath" alt="" /></div>-->
<!--            <div>{{ currentDetail.summary }}</div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import require from "../services";
import $Config from "../config/config";
import CTitle from "@/components/Title.vue";

export default {
  name: "MeetingGuide",
  components: { CTitle },
  props: {
    msg: String,
  },
  data() {
    return {
      siteInfo: {},
      epodemicInfo: {},
      trafficInfo: {},
      currentDetail: {},
      showPcModal: false,
      showWapModal: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const info = await require.getCategoryArticleList($Config.guideId);
      if (info.result == "0") {
        info.content.items.forEach((item) => {
          item.newTitle = item.title.split("^|")[0];
          item.newTip = item.title.split("^|")[1];
          if (item.extra.customRadio_2570.value == "场地规划布局") {
            this.siteInfo = item;
          } else if (item.extra.customRadio_2570.value == "交通信息") {
            this.trafficInfo = item;
          } else if (item.extra.customRadio_2570.value == "防疫政策") {
            this.epodemicInfo = item;
          }
        });
      }
    },
    closeDialog() {
      this.showPcModal = false;
      this.showWapModal = false;
    },
   async openCurrenModal(item) {
     const info = await require.getArticleDetail({articleId:item.id});
     info.content.newTitle = info.content.title.split("^|")[0];
     info.content.newTip = info.content.title.split("^|")[1];
     this.currentDetail = info.content;
      if (document.body.clientWidth > 750) {
        this.showPcModal = true;
      } else {
        this.showWapModal = true;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
