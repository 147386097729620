<template>
  <div>
    <div v-if="!isLoading">
      <router-view/>
    </div>
    <div v-if="isLoading">
      <Loading></Loading>
    </div>
  </div>
</template>
<script>
import {getGlobalUserId} from './utils/index';
import $config from './config/config';
import require from './services/index';
import Loading from '@/components/Loading.vue';
import {lives} from '@/common/data';
import Cookies from 'js-cookie';

export default {
  name: 'App',
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: true,
      webinarInfo: {},
      MainWebinarId:'',
      webinarId:''
    };
  },
  created() {
    if(window.location.href.indexOf('local')>-1){
      this.webinarId=$config.twebinarId;
    }else {
      this.webinarId=$config.webinarId;
    }
    this.init();
  },
  methods: {
    async init() {
      getGlobalUserId();
      const info = await this.initWebinarInfo();
      const info1 = await require.getContacts(this.webinarId);
      if (info.result == '0') {
        info.content.webinarInfo.customFieldItems.forEach((item) => {
          if (item.fieldName == "mettingStatus") {
            if (item.value == '' || item.value == '会前') {
              $config.mettingStatus = '1';
            } else if (item.value == '会中上午') {
              $config.mettingStatus = '2';
            } else if (item.value == '会中下午') {
              $config.mettingStatus = '3';
            } else if (item.value == '会议结束') {
              $config.mettingStatus = '4';
            } else if (item.value == '会后') {
              $config.mettingStatus = '5';
            }
          }
        });
        //临时限制
        // $config.mettingStatus =  '1' ;
        this.webinarInfo = info.content.webinarInfo;
        $config.webinarInfo = this.webinarInfo;

      }
      if (info1.result == '0') {
        if (info1.content && parseInt(info1.content.signUpId) > 0) {

          const formData=[];
          $config.mobile=  info1.content.contact.mobile;
          Object.keys(info1.content.contact).map((key)=>{
            formData.push({
              fieldName: key,
              value: info1.content.contact[key],
            })
          });
          $config.formData=formData;
          $config.mainIsSignUp = true;
          let i=0;
          lives.forEach((item,index)=>{
            if (Cookies.get('contactsLogin'+ item.webinarId)) {
              console.log('contactsLogin'+ item.webinarId);
              if (index + 1 >= lives.length){
                this.isLoading = false;
              }
              return;
            }
            Cookies.set('contactsLogin'+ item.webinarId, "1", {
              expires: 1
            });
            require.contactsLogin({
              value: $config.mobile,
              webinarId: item.webinarId
            }).then((res)=>{
              if (res.content && parseInt(res.content.signUpId) > 0) {
                   i=i+1;
                if(i==lives.length){
                  this.isLoading = false;
                }
              }else {
                require.silentSignUp({
                  formData:formData,
                  webinarId:item.webinarId,
                  moduleId: 2,
                  tenantId: $config.tenantId,
                  formId:  item.customFormId,
                  channel: this.$route.query.markCode || '',
                  weChatId: '',
                  openId:'',
                  sess: '',
                  isPreview: '0',
                  signUpSource: this.isMobile ? 'WAP' : 'PC',
                  isUnLimit: 0,  //无限制观看静默报名 1 是 0 否
                  // markCode 与 marketingSess 为用于统计的埋点相关参数
                  'markCode': this.$route.query.markCode || '',
                }).then(()=>{
                  i=i+1;
                  if(i==lives.length){
                    this.isLoading = false;
                  }
                })
              }
            })

          });
        } else {
          $config.mainIsSignUp = false;
          this.isLoading = false;
        }
      }
    },
    initWebinarInfo() {

      return require.getSwebinarInfo(this.webinarId);
    },
  }

};

</script>
<style lang="scss"></style>
